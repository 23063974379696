import { useEffect } from "react";
import { getSections } from "./SecondaryNav";
import { jumpToSection } from "./jumpToSection";

export function useScrollToSection() {
    useEffect(() => {
        if (!document.body.classList.contains("enable-scrolling-to-sections")) return;
        const validSection = seeIfURLContainsAValidSection();
        if (!validSection) return;

        jumpToSection(validSection as string);

        //do it again to account for layout shifts.
        [100,800].forEach((time) => {
            setTimeout(() => {
                jumpToSection(validSection as string);
            }, time);
        });

    }, []);
}

function seeIfURLContainsAValidSection() {
    //get all valid sections
    const sections = getSections();
    var lastURLPart = getLastPartOfURL();

    //check if the last part of the URL is a valid section
    const validSection = sections.find((section) => section.id === lastURLPart);
    if (validSection) return validSection.id;
    const validSection2 = sections.find((section) => section.id.replace("'", "") === lastURLPart.replace("'", ""));
    if (validSection2) return validSection2.id;
    return false;
}

function getLastPartOfURL() {
    const currentURL = window.location.href;
    var URLParts = currentURL.split("/");
    var lastURLPart = URLParts.pop() ?? "";
    if (lastURLPart == "") lastURLPart = URLParts.pop() ?? "";
    return lastURLPart;
}

