import debounce from "lodash/debounce";
import React, { RefObject, useCallback, useEffect, useRef, useState } from "react";

export function useHeroObserver() {
    const [ displayClass, setDisplayClass ] = useState<string>("d-none");

    const observerHeroRef = useRef<IntersectionObserver | null>(null);

    const heroElement = document.getElementsByClassName("hero")[0];

    useEffect(() => {
        //intersection observer options
        let options = {
            root: null,
            rootMargin: `0px 0px 0px 0px`,
            threshold: 0,
        };

        observerHeroRef.current = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setDisplayClass("d-none");
            }
            else if (window.innerWidth < 992) {
                setDisplayClass("d-block");
            }
            else {
                setDisplayClass("d-none");
            }
        }, options);

        if (heroElement) { observerHeroRef.current?.observe(heroElement); }

        window.addEventListener("resize", debounce(() => {
            observerHeroRef.current?.unobserve(heroElement);
            observerHeroRef.current?.observe(heroElement);
        }, 300))

        return function () {
            //On unmount, remove observer;
            observerHeroRef.current?.disconnect();
        };
    }, [])

    return displayClass;
}