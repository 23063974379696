import React from 'react';
import { useInView } from 'react-intersection-observer';
import { getRootoffSetMargins } from '../blocks/secondaryNav/useInViewObserver';

export function LazyComponent(props) {

    const { negativeMarginTop } = getRootoffSetMargins();

    const { ref, inView, entry } = useInView({
        triggerOnce: true,
        threshold: 0,
        rootMargin: `${negativeMarginTop} 0px 0px 0px`
    });

    const {component: Component, loadImmediately: LoadImmediately = false} = props;

    // const Component = props.component;
    // const LoadImmediately = props.loadImmediately ?? false;

    if(!inView && !LoadImmediately) return (
        <div ref={ref} className="component-loading">
            Loading...
        </div>
    );

    return (
        <Component rootElement={ props.rootElement}></Component>
    )
};