import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { LazyComponent } from "./LazyComponent";
import "./LazyComponent.scss";

import SecondaryNav from '../blocks/secondaryNav/SecondaryNav';

const ContentAreaBlock = React.lazy(() => import('../blocks/ContentAreaBlock'));
const HTMLBlock = React.lazy(() => import('../blocks/HtmlBlock'));
const PersonUsBlock = React.lazy(() => import('../Components/PersonUsBlock/PersonUsBlock'));
const VideoUsBlock = React.lazy(() => import('../Components/VideoUsBlock/VideoUsBlock'));
const TabContentBlock = React.lazy(() => import('../Components/TabContentBlock/TabContentBlock'));
const MediaGridBlock = React.lazy(() => import('../Components/MediaGridBlock/MediaGridBlock'));
const ArticleSourceBlock = React.lazy(() => import('../Components/ArticleSourceBlock/ArticleSourceBlock'));
const ArticleCarouselBlock = React.lazy(() => import('../Components/ArticleCarouselBlock/ArticleCarouselBlock'));
const FundCarouselBlock = React.lazy(() => import('../Components/FundCarouselBlock/FundCarouselBlock'));
const FeatureCallOutBlock = React.lazy(() => import('../Components/FeatureCallOutBlock/FeatureCallOutBlock'));
const RelatedMediaCarouselBlock = React.lazy(() => import('../Components/RelatedMediaCarouselBlock/RelatedMediaCarouselBlock'));
const PDFCarouselBlock = React.lazy(() => import('../Components/PDFCarouselBlock/PDFCarouselBlock'));
const CustomFundCarouselBlock = React.lazy(() => import('../Components/CustomFundCarouselBlock/CustomFundCarouselBlock'));
const RecommendationsBlock = React.lazy(() => import('../../ReactUS/Components/RecommendationsBlock/RecommendationsBlock'));
const HybridInsightsBlock = React.lazy(() => import('../Components/HybridInsightsBlock/HybridInsightsBlock'));
const CompoundHeroBlock = React.lazy(() => import('../Components/CompoundHeroBlock/CompoundHeroBlock'));

//Bots
const isCrawler = /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);

const blockTypes = [
    {
        name: "HybridInsightsBlock",
        tagName: "ve-hybrid-insights-grid-block",
        component: HybridInsightsBlock,
        LoadImmediately: true,
        template: ""
    },
    {
        name: "CompoundHeroBlock",
        tagName: "ve-compound-hero",
        component: CompoundHeroBlock,
        LoadImmediately: true,
        template: ""
    },
    {
        name: "RecommendationsBlock",
        tagName: "ve-recommendations-block",
        component: RecommendationsBlock,
        LoadImmediately: true,
        template: ""
    },
    {
        name: "SecondaryNav",
        tagName: "ve-secondarynav",
        component: SecondaryNav,
        LoadImmediately: true
    },
    {
        name: "ContentAreaBlock",
        tagName: "ve-contentareablock",
        component: ContentAreaBlock,
        LoadImmediately: false,
        template: ""
    },
    {
        name: "HTMLBlock",
        tagName: "ve-htmlblock",
        component: HTMLBlock,
        LoadImmediately: false,
        template: ""
    },
    {
        name: "PersonUsBlock",
        tagName: "ve-contentareablock",
        component: PersonUsBlock,
        LoadImmediately: false,
        template: "PersonUsBlocks",
        IgnoreForCrawlers: true
    },
    {
        name: "MediaGridBlock",
        tagName: "ve-contentareablock",
        component: MediaGridBlock,
        template: "MediaGridBlock",
        LoadImmediately: false,
    },
    {
        name: "VideoUsBlock",
        tagName: "ve-videousblock",
        component: VideoUsBlock,
        LoadImmediately: false,
    },
    {
        name: "ArticleSourceBlock",
        tagName: "ve-contentareablock",
        component: ArticleSourceBlock,
        template: "ArticleSourceBlock",
        LoadImmediately: false,
    },
    {
        name: "ArticleCarouselBlock",
        tagName: "ve-contentareablock",
        component: ArticleCarouselBlock,
        template: "ArticleCarouselBlock",
        LoadImmediately: false,
    },
    {
        name: "RelatedMediaCarouselBlock",
        tagName: "ve-relatedmediablock",
        component: RelatedMediaCarouselBlock,
        template: "",
        LoadImmediately: false,
    },
    {
        name: "FeatureCallOutBlock",
        tagName: "ve-contentareablock",
        component: FeatureCallOutBlock,
        template: "FeatureCallOutBlock",
        LoadImmediately: false,
    },
    {
        name: "TabContentBlock",
        tagName: "ve-contentareablock",
        component: TabContentBlock,
        template: "TabContentBlock",
        LoadImmediately: false,
    },
    {
        name: "PDFCarouselBlock",
        tagName: "ve-contentareablock",
        component: PDFCarouselBlock,
        template: "PDFCarouselBlock",
        LoadImmediately: false,
    },
    {
        name: "FundCarouselBlock",
        tagName: "ve-fundcarouselusblock",
        component: FundCarouselBlock,
        LoadImmediately: false,
    },
    {
        name: "CustomFundCarouselBlock",
        tagName: "ve-contentareablock",
        component: CustomFundCarouselBlock,
        template: "CustomFundCarouselBlock",
        LoadImmediately: false,
    },
]

export function processMarkup(rootElem = document) {
    //If it is a crawler, remove the blocks that are too slow to load.
    let blockTypesToRender = blockTypes;
    if (isCrawler) {
        blockTypesToRender = blockTypes.filter(block => {
            return typeof(block.IgnoreForCrawlers) == "undefined" || block.IgnoreForCrawlers == false;
        })
    }

    for (const block of blockTypesToRender) {
        var containers = rootElem.getElementsByTagName(block.tagName)
        if (block.template != null) {
            containers = Array.from(containers).filter(x => x.dataset.template == block.template)
        }
        for (const container of containers) {
            const root = createRoot(container);
            const { component, LoadImmediately } = block;
            root.render(
                <Suspense fallback={<div className="component-loading">Loading...</div>}>
                    <LazyComponent component={component} rootElement={container} loadImmediately={LoadImmediately} />
                </Suspense>
            );
        }
    }
}