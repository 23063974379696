import React, { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { ISection } from "./SecondaryNav.types";
import { setSecondaryNavState } from "./setSecondaryNavState";
import debounce from "lodash/debounce";
import { jumpToSection } from "./jumpToSection";
import { getHeaderHeight, HeaderHeightEvent } from "./SecondaryNav";
import { useHeroObserver } from "./useHeroObserver";
import throttle from "lodash/throttle";

type IWindowDimensions = {
    height: any;
    width: any;
};

type IOffsetMargins = {
    negativeMarginTop: string;
    negativeMarginBottom: string;
};

/**
 * Hook that observes all of the sections and changes a class based on if it is intersects the bottom of the sticky header.
 * @param Sections Array of fund sections.
 * @param setSections StateSetter for FundSections.
 */
export function useInViewObserver(
    Sections: ISection[],
    setSections: React.Dispatch<React.SetStateAction<ISection[]>>
) {
    const observerRef = useRef<IntersectionObserver | null>(null);

    const [windowDimensions, setWindowDimensions] = useState<IWindowDimensions>({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    const [rootOffsetMargins, setrootOffsetMargins] = useState(
        getRootoffSetMargins()
    );

    useHeroObserver();

    //effect to set offsetmargins upon resize.
    useEffect(
        function () {
            const { negativeMarginTop, negativeMarginBottom } = rootOffsetMargins;

            //intersection observer options
            let options = {
                root: null,
                rootMargin: `${negativeMarginTop} 0px ${negativeMarginBottom} 0px`,
                threshold: 0,
            };

            observerRef.current = new IntersectionObserver(function (
                entries,
                observer
            ) {
                const SectionsAlreadyActive = Array.from(
                    document.querySelectorAll(".sidebar-nav__item.active")
                ).map((x) => (x as HTMLElement).dataset.target);
                const FilteredSectionsAlreadyActive = SectionsAlreadyActive.filter(
                    (x) => x != undefined
                ) as string[];
                entries.forEach((entry) => {
                    const elementId = entry.target.id;
                    const section = Sections.find((x) => x.id === elementId);
                    if (!section) return;
                    if (entry.isIntersecting) {
                        //unset all existing items
                        if (SectionsAlreadyActive.length > 0)
                            setSecondaryNavState(
                                Sections,
                                setSections,
                                FilteredSectionsAlreadyActive,
                                ""
                            );
                        //set the new one
                        setSecondaryNavState(Sections, setSections, [elementId], "active");
                    }
                });
            },
                options);

            //Observe each targeted element.
            Sections.forEach((Section) => {
                const targetedElement = document.getElementById(Section.id as string);
                if (!targetedElement) return;
                observerRef.current?.observe(targetedElement);
            });

            return function () {
                //On unmount, remove observer;
                observerRef.current?.disconnect();
            };
        },
        [rootOffsetMargins] //The hook is called everytime the brower is resized.
    );

    useEffect(() => {
        const observerScrollHandler = throttle(() => {
            setrootOffsetMargins(getRootoffSetMargins());
        }, 500)

        window.addEventListener("scroll", observerScrollHandler);

        return function () {
            window.removeEventListener("scroll", observerScrollHandler);
        };

    }, [])

    //listen for window resizes
    useEffect(() => {
        const resizeHandler = debounce(function () {
            if (
                windowDimensions.width != window.innerWidth ||
                windowDimensions.height != window.innerHeight
            ) {
                setWindowDimensions(() => {
                    return {
                        height: window.innerHeight,
                        width: window.innerWidth,
                    };
                });

                //Trigger a root offset margin reset. Recalculate and reset the intersection observer.
                setrootOffsetMargins(getRootoffSetMargins());
            }
        }, 300);

        window.addEventListener("resize", resizeHandler);

        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);

    //now listen to alertbar changes
    const headerHeightEventHandler = useCallback((e: HeaderHeightEvent) => {
        //Trigger a root offset margin reset. Recalculate and reset the intersection observer.
        setrootOffsetMargins(getRootoffSetMargins());
    }, []);

    //now trigger it once incase anything messes up during the initial load.
    useEffect(() => {
        window.setTimeout((x: any) => setrootOffsetMargins(getRootoffSetMargins()), 500);
    }, [])


    useEffect(() => {
        //Listener to listen to any alert bars being added.
        document.addEventListener("headerHeightEvent", (e) =>
            headerHeightEventHandler(e)
        );

        return () => {
            document.removeEventListener("headerHeightEvent", (e) =>
                headerHeightEventHandler(e)
            );
        };
    }, []);

    useEffect(function () {
        const popStateHandler = function (event: PopStateEvent) {
            console.log(
                `Popstate triggered: location: ${document.location
                }, state: ${JSON.stringify(event.state)}`
            );
            const newState = event.state?.section ?? false;
            if (!newState || typeof newState != "string") return;
            window.setTimeout(() => jumpToSection(newState), 0);
        };

        window.addEventListener("popstate", popStateHandler);

        return () => {
            window.removeEventListener("popstate", popStateHandler);
        };
    }, []);
}

export function getRootoffSetMargins(): IOffsetMargins {
    const HeaderHeight = getHeaderHeight() ?? 0;
    const offsetFromBottomOfHeader = 90 + (window.innerWidth > 576 ? 30 : 44);
    const VisibleScrollableHeight = window.innerHeight - (HeaderHeight + offsetFromBottomOfHeader); // (minus offset)
    const negativeMarginTop = (HeaderHeight + offsetFromBottomOfHeader) * -1 + "px";
    const negativeMarginBottom = (VisibleScrollableHeight - 2) * -1 + "px";
    return { negativeMarginTop, negativeMarginBottom };
}


