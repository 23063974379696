import { getHeaderHeight } from "./SecondaryNav";

export function jumpToSection(targetedElementId: string) {
    const targettedElement = document.getElementById(targetedElementId);
    const mobileOffset = (window.innerWidth > 576 ? 30 : 60);
    if (!targettedElement)
        return;

    const headerHeight = getHeaderHeight();
    const targetElementTop = targettedElement.getBoundingClientRect().top + document.documentElement.scrollTop;

    console.log("Target Element Top: ", targetElementTop);

    window.scrollTo({
        behavior: "auto",
        top: targetElementTop - headerHeight - mobileOffset,
    });
}

