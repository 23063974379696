import React, { MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ISection } from "./SecondaryNav.types";
import { useInViewObserver } from "./useInViewObserver";
import "./secondaryNav.scss";
import { jumpToSection } from "./jumpToSection";
import { useScrollToSection } from "./useScrollToSection";
import { getCleanPageURL } from "./setSecondaryNavState";
import { useHeroObserver } from "./useHeroObserver";

export interface ISecondaryNav extends ComponentInterface { }

//Handle bots
const isCrawler = /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
//Whether or not to render <a> tags around secondary nav items. Might be needed for Crawlers.
const addLinksToSecondaryNav = false;

export default function SecondaryNav(props: ISecondaryNav) {
    const element = props.rootElement;
    element.classList.remove("d-none");
    const [Sections, setSections] = useState(getSections());
    const cleanURL = getCleanPageURL(Sections);

    useInViewObserver(Sections, setSections);

    return (
        <>
            <StickyHeader Sections={Sections} />
        </>
    );
}

export interface HeaderHeightEvent extends Event {
    detail?: {
        headerHeight: number | undefined,
        cumilativeAlertBarsHeight: number | undefined
    }
}

function StickyHeader({ Sections }: { Sections: ISection[] }) {
    const activeSectionLabel = Sections.find((x) => x.activeClass == "active")?.name ?? "";
    const [secondaryNavTop, setsecondaryNavTop] = useState(0);
    const cleanURL = getCleanPageURL(Sections);

    /**
     * The alertbar code will dispatch an event to document whenever the alertbars are added or removed from the page.
     * We need to listen to these events and adjust the offset of our sticky nav accordingly.
     * Refer generic-promo-banner.js for details of dispatcher.
     */
    const headerHeightEventHandler = useCallback(
        (e: HeaderHeightEvent) => {
            const HeaderHeight = getHeaderHeight();

            if (!e.detail) return;
            const secondayNavOffsettop = (e.detail.headerHeight ?? HeaderHeight) + (e.detail.cumilativeAlertBarsHeight ?? 0);
            setsecondaryNavTop(secondayNavOffsettop);
        },
        [],
    )

    const secondaryNavTopEventHandler = () => {
        setsecondaryNavTop(getHeaderHeight());
    }

    const displayClass = useHeroObserver();

    useEffect(() => {
        const HeaderHeight = getHeaderHeight();
        setsecondaryNavTop(HeaderHeight);

        //Listener to listen to any alert bars being added and screen orientation change.
        document.addEventListener("headerHeightEvent", e => headerHeightEventHandler(e));

        window.addEventListener("resize", secondaryNavTopEventHandler);

        return () => {
            document.removeEventListener("headerHeightEvent", e => headerHeightEventHandler(e));

            window.removeEventListener("resize", secondaryNavTopEventHandler);
        }
    }, []);

    useScrollToSection();

    return (<>
        <nav className="sticky-left-sidebar" aria-label="Jump to">
            <ul className="sidebar-nav">
                {Sections &&
                    Sections.map((Section) => (
                        <SecondaryNavItem
                            key={Section.id as React.Key}
                            Section={Section}
                            displayClass="sidebar-nav__item"
                            cleanURL={cleanURL}
                        ></SecondaryNavItem>
                    ))}
            </ul>
        </nav>
        <div className="secondary-nav-wrp fixed-nav">
            <div className={`sticky-header ${displayClass}`} style={{ top: secondaryNavTop }}>
                <div className="container">
                    <div className="inner-container">
                        <div className="md-secondary-nav dropdown align-items-center">
                            <span className="text-util-md pr-2">{activeSectionLabel}</span>
                            <DesktopDropdownButton buttonClass={null} buttonText={null} isMobile={false} />
                            <nav className="dropdown-menu dropdown-menu-right" aria-label="Jump to">
                                {Sections &&
                                    Sections.map((Section) => (
                                        <SecondaryNavItem
                                            key={Section.id as React.Key}
                                            Section={Section}
                                            displayClass="dropdown-item text-util-md"
                                            cleanURL={cleanURL}
                                        ></SecondaryNavItem>
                                    ))}
                            </nav>
                        </div>
                    </div>
                    <div className="inner-container-mobile">
                        <div className="mobile-secondary-nav dropdown align-items-center">
                            <DesktopDropdownButton buttonClass={null} buttonText={activeSectionLabel as string} isMobile={true} />
                            <nav className="dropdown-menu dropdown-menu-right" aria-label="Jump to">
                                {Sections &&
                                    Sections.map((Section) => (
                                        <SecondaryNavItem
                                            key={Section.id as React.Key}
                                            Section={Section}
                                            displayClass="dropdown-item text-util-md"
                                            cleanURL={cleanURL}
                                        ></SecondaryNavItem>
                                    ))}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>                 
    );
}

export function getHeaderHeight() {
    const headers = document.querySelectorAll(".root header.site-header");
    const headerNativeHeight = [...headers].reduce((prev, headerElement) => {
        return prev + Math.round(
            headerElement?.getBoundingClientRect().height ?? 0
        );
    }, 0);

    return headerNativeHeight;
}

//declare jQuery bootstrap;
declare global {
    interface Window { $: Function | any }
}

function NavigationLinks() {
    const navLinks = useMemo(() => getNavigationLinks(), [])
    const navRefs = useRef<HTMLAnchorElement | null>(null);

    useEffect(function () {
        if (navRefs.current != null) {
            navRefs.current.addEventListener("click", function (e) {
                e.preventDefault();
                window.$("#id-how-to-buy-etf-modal").modal();
            })
        }
    }, [])

    return <div className="sticky-links">
        {navLinks.map((link, index) => {
            return (<a href={link.url as string} data-cssclass={link.cssClass} data-ve-gtm={link.gtmAttribute} key={index} target={link.target} className="sticky-link px-3 text-util-md" ref={ref => link.cssClass.includes("id-how-to-buy-etf-modal") ? navRefs.current = ref : false}>
                {link.title}
            </a>)
        })}
    </div>;
}

type INavigationLinks = {
    title: string,
    url: string,
    cssClass: string,
    gtmAttribute: string,
    target: string
}

function getNavigationLinks(): INavigationLinks[] {
    const navLinks = document.getElementsByTagName("ve-secondarynavlinks");
    const FirstLink = navLinks[0];
    if (FirstLink == null) return [];
    const linkItems = Array.from(FirstLink.getElementsByTagName("ve-linkitem")) as HTMLElement[];
    const linkList = linkItems.map(link => {
        return {
            title: link.dataset.title ?? "",
            url: link.dataset.url ?? "",
            cssClass: link.dataset.cssclass ?? "",
            gtmAttribute: link.dataset.gtmtag ?? "",
            target: link.dataset.target ?? "_self"
        };
    });
    return linkList;
}

export function getSections(): ISection[] {
    const SectionNodes = document.querySelectorAll(".jumplink-section");
    return Array.prototype.map.call(SectionNodes, function (sectionNode: Element): ISection {
        return {
            id: sectionNode.id,
            name: sectionNode.getAttribute("data-section") ?? null,
            activeClass: "",
        };
    }) as ISection[];
}

const clickListenerForSeondaryNavAnchors: MouseEventHandler<HTMLAnchorElement> = (e) => !isCrawler && e.preventDefault();

export function SecondaryNavItem({ Section, displayClass, cleanURL }: { Section: ISection; displayClass: string, cleanURL: string }) {
    const { name, id, activeClass } = Section;
    const url = `${cleanURL}/${id}/`;

    return (
        <li className={displayClass + " " + activeClass} data-target={id} onClick={handleClick}>
            {addLinksToSecondaryNav ?
                <a href={url} onClick={clickListenerForSeondaryNavAnchors} className="secondary-nav-link">{name}</a> : <>{name}</>}
        </li>
    );
}

function handleClick(event: React.MouseEvent<HTMLElement>) {
    if(isCrawler) return true;
    const currentTarget = event.currentTarget;
    const targetedElementId = currentTarget.dataset.target ?? "";
    jumpToSection(targetedElementId);
}

const DesktopDropdownButton = function (props: {
    buttonText: string | null;
    buttonClass: string | null;
    isMobile?: Boolean | false;
}) {
    const { buttonText, buttonClass, isMobile } = props;
    const text = buttonText ?? "";
    const classText = buttonClass ?? "dropdown__button-simple-toggle";
    return (
        <>
            {(text != "" || !isMobile) && (
                <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className={`dropdown__button ${classText} dropdown__button-default`}
                    data-toggle="dropdown"
                >
                    {text != "" && <span className="text-util-md dropdown-label">{text}</span>}
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="far"
                        data-icon="chevron-down"
                        className="svg-inline--fa fa-chevron-down fa-w-16 fa-2x dropdown__icon dropdown__icon-default"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 18 18"
                    >
                        <path
                            fill="currentColor"
                            d="M2.26 4.88a.75.75 0 00-.54.21.75.75 0 000 1.07l6.75 6.75a.75.75 0 001.06 0l6.75-6.75a.75.75 0 000-1.07.75.75 0 00-1.06 0L9 11.31 2.78 5.1a.75.75 0 00-.52-.21z"
                        ></path>
                    </svg>
                </button>
            )}
        </>
    );
};
