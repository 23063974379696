import React from "react";
import { ISection } from "./SecondaryNav.types";

export function setSecondaryNavState(sections: ISection[], setSections: React.Dispatch<React.SetStateAction<ISection[]>>, selectedSectionIds: String[], activeClass: String) {
  //set the react state
  setSections(currentSections => {
    return [...currentSections].map(section => {
      if (selectedSectionIds.includes(section.id)) {
        return {
          ...section,
          activeClass: activeClass
        }
      }
      return section;
    })
  });
}

export function getCleanPageURL(sections: ISection[]) {
    //Get all the valid section IDs
    const allValidSectionIDs = sections.map(section => section.id);
    //get all the path tokens and reverse it to speed up search
    let pathtokens = window.location.pathname.split("/").reverse();

    //see if the any of the token match the available indexes.
    const currentSection = allValidSectionIDs.find(section => pathtokens.find(x => x == section));
    if (currentSection) {
        //replace any current path token (current section) with an empty string.
        pathtokens.forEach((token, index) => {
            if (token != currentSection) return;
            pathtokens[index] = "";
        })
    }

    pathtokens.reverse();
    if (pathtokens[pathtokens.length - 1] == "") pathtokens.pop(); // = remove trailing slash
    const url = pathtokens.join("/");

    const newUrl = url.endsWith("/") ? url.slice(0, url.length-1) : url;

    return newUrl;
}